{
  "id": "maize",
  "name": "Gramene Maize",
  "version": "v2",
  "description": "Comparative plant genomics focused on maize varieties",
  "url": "https://maize-pangenome.gramene.org",
  "ensemblURL": "https://maize-pangenome-ensembl.gramene.org",
  "ensemblSite": "https://maize-pangenome-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.gramene.org/maizepan1",
  "targetTaxonId": 4577,
  "portals": ['browser','blast','pansites'],
  "portals2": ['pansites','browser','reactome','blast','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "date": "2022-03-30"
}
