{
  "id": "grapevine",
  "name": "Gramene Grapevine",
  "version": "v3",
  "description": "Comparative plant genomics focused on grapevine varieties",
  "url": "https://vitis.gramene.org",
  "ensemblURL": "https://vitis-ensembl.gramene.org",
  "ensemblSite": "https://vitis-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.gramene.org/grapevine_v3",
  "curation": {
    "url": "http://curate.gramene.org/grapevine_v3?since=71275&gene=",
    "taxa": {
      "297600000": 1
    }
  },
  "targetTaxonId": 297600000,
  "portals": ['browser','blast','pansites'],
  "portals2": ['pansites','browser','reactome','climtools','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "date": "2022-03-30"
}