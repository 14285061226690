{
  "id": "sorghum",
  "name": "Sorghumbase",
  "version": "v3",
  "description": "Genomic resources for the sorghum research community",
  "url": "https://www.sorghumbase.org",
  "ensemblURL": "https://ensembl.sorghumbase.org",
  "ensemblSite": "https://ensembl.sorghumbase.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.sorghumbase.org/sorghum_v3",
  "targetTaxonId": 4588,
  "alertText": "Click the search icon in the menu bar or type / to search",
  "portals": ['browser','blast','pansites'],
  "portals2": ['pansites','browser','reactome','climtools','blast','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true
}