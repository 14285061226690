{
  "id": "oryza",
  "name": "Gramene Oryza",
  "version": "v4",
  "description": "Comparative plant genomics focused on rice varieties",
  "url": "https://oryza.gramene.org",
  "ensemblURL": "https://oryza-ensembl.gramene.org",
  "ensemblSite": "https://oryza-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.gramene.org/oryza_v4",
  "curation": {
    "url": "http://curate.gramene.org/oryza_v4?since=4479&gene=",
    "taxa": {
      "39947": "Oryza sativa Japonica",
    }
  },
  "targetTaxonId": 3702,
  "portals": ['browser','blast','pansites'],
  "portals2": ['pansites','browser','reactome','blast','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "date": "2022-05-30"

}
