{
  "id": "main",
  "name": "Gramene",
  "version": "v65",
  "description": "Comparative plant genomics for crops and model organisms",
  "url": "https://www.gramene.org",
  "ensemblURL": "https://ensembl.gramene.org",
  "ensemblSite": "https://ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/ensembl65",
  "grameneData": "https://data.gramene.org/v65",
  "targetTaxonId": 3702,
  "portals": ['browser','reactome','pansites','blast','climtools','atlas','mart','tools','downloads','archive'],
  "portals2": ['pansites','browser','reactome','climtools','blast','mart','tools','atlas','trackhub','outreach','downloads','archive'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": false,
  "showFeed": true,
  "showTweets": true,
  "date": "2022-04-28"
}
