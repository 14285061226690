var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import remarkGfm from 'remark-gfm';
import { Remark } from 'react-remark';
import remarkHeading from 'remark-heading-id';
import imgLinks from '@pondorasti/remark-img-links';
import { Container, Row, Col, Navbar, Nav, Table } from 'react-bootstrap';
import '../css/mdview.css';

var _default = function (_Component) {
  _inherits(_default, _Component);

  function _default(props) {
    _classCallCheck(this, _default);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.state = {
      currentFile: 0
    };
    return _this;
  }

  _default.prototype.componentDidMount = function componentDidMount() {
    var _this2 = this;

    var md_regex = /---[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]\.md$/i;
    var url = 'https://api.github.com/repos/' + this.props.org + '/' + this.props.repo + '/contents/' + this.props.path;
    fetch(url).then(function (response) {
      return response.json();
    }).then(function (data) {
      var now = _this2.props.date ? new Date(_this2.props.date) : new Date();
      var mdFiles = data.filter(function (f) {
        return md_regex.test(f.name);
      }).map(function (f) {
        f.name = f.name.replace(/\.[^/.]+$/, "");

        var _f$name$split = f.name.split('---'),
            name = _f$name$split[0],
            datestr = _f$name$split[1];

        f.name = name;
        f.date = new Date(datestr);
        return f;
      }).filter(function (f) {
        return f.date < now;
      }).sort(function (a, b) {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      });
      _this2.setState({ files: mdFiles });
      mdFiles.forEach(function (f) {
        fetch(f.download_url).then(function (response) {
          return response.text();
        }).then(function (content) {
          f.content = content;
          _this2.setState({ files: mdFiles });
        });
      });
    });
  };

  _default.prototype.renderFileList = function renderFileList() {
    var _this3 = this;

    if (!this.state.files) {
      return React.createElement('p', null);
    }
    if (this.state.files.length === 0) {
      return React.createElement('p', null);
    }
    var c = this.state.currentFile;
    var handleSelect = function handleSelect(eventKey) {
      return _this3.setState({ currentFile: eventKey });
    };
    return React.createElement(
      Navbar,
      { bg: 'light' },
      React.createElement(
        Nav,
        { className: 'flex-column', activeKey: c, onSelect: handleSelect },
        React.createElement(
          Nav.Item,
          null,
          React.createElement(
            'h5',
            null,
            this.props.heading || 'Files'
          )
        ),
        this.state.files.map(function (f, i) {
          return React.createElement(
            Nav.Link,
            { key: i, eventKey: i },
            f.name
          );
        })
      )
    );
  };

  _default.prototype.renderFile = function renderFile() {
    if (!this.state.files) {
      return React.createElement(
        'p',
        null,
        this.props.ifEmpty || 'No matching files found in ' + this.props.org + '/' + this.props.repo + '/' + this.props.path
      );
    }
    if (this.state.files.length === 0) {
      return React.createElement(
        'p',
        null,
        'no files found in ',
        React.createElement(
          'code',
          null,
          'https://github.com/' + this.props.org + '/' + this.props.repo + '/' + this.props.path
        )
      );
    }
    var c = this.state.currentFile;
    var f = this.state.files[c];
    return React.createElement(
      'div',
      { className: 'mdview' },
      f.content && React.createElement(
        Remark,
        {
          remarkPlugins: [remarkGfm, remarkHeading, [imgLinks, { absolutePath: 'https://github.com/' + this.props.org + '/' + this.props.repo + '/raw/main/' + this.props.path + '/' }]],
          rehypeReactOptions: {
            components: {
              table: function table(props) {
                return React.createElement(Table, _extends({ size: 'sm', striped: true, bordered: true, hover: true }, props));
              }
            }
          }
        },
        f.content
      )
    );
  };

  _default.prototype.render = function render() {
    return React.createElement(
      Container,
      { fluid: true },
      React.createElement(
        Row,
        null,
        React.createElement(
          Col,
          { sm: 3 },
          this.renderFileList()
        ),
        React.createElement(
          Col,
          { sm: 9 },
          this.renderFile()
        )
      )
    );
  };

  return _default;
}(Component);

export { _default as default };